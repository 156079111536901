import { MemberReferralData } from "@app/core/api/index";
import { ITableItem } from "@app/shared/components/table/models";

export interface RefferedPerson extends ITableItem {
    processedAt: string;
    process: string;
    fullName: string;
    email: string;
    phone: string;
    location: string;
    clubName: string;
    barcode: string;
}

export const mapToRefferedPersonsFromMemberReferralData = (response: MemberReferralData[]): RefferedPerson[] => {
    return response?.map(
        (memberReferralData: MemberReferralData): RefferedPerson => ({
            processedAt: memberReferralData.processedAt || "",
            process: mapProcessDataFromReferralBaseData(memberReferralData.process!) || "",
            fullName: memberReferralData.referredPersonFullName || "",
            email: memberReferralData.email || "",
            phone: memberReferralData.phoneNumber || "",
            location: memberReferralData.clubCode || "",
            clubName: memberReferralData.clubName || "",
            barcode: memberReferralData.referredPersonBarcode || "",
            referredPersonId: memberReferralData.referredPersonId || "",
        }),
    );
};

export const mapProcessDataFromReferralBaseData = (process: string): string => {
  switch (process) {
      case "InClub":
          return "Club check-in";
      case "SevenDayPass":
          return "7-day pass";
      case "JoinNow":
          return "Join now";
  }
  return "";
};
